<template>
  <div class="stevedoring-account bj">
    <div class="auto-table-title">
      <el-button
        type="primary"
        icon="el-icon-refresh"
        class="bjsearch"
        @click="Refresh"
      ></el-button>
      <div class="seach">
        <el-button @click="Addfleet" type="primary">添加</el-button>
      </div>
    </div>

    <div class="auto-table-flex">
      <el-table
        v-loading="loading"
        :data="tableData"
        height="100%"
        :border="true"
        :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
      >
        <el-table-column type="index" align="center" label="序号" width="100" />
        <el-table-column align="center" label="姓名" prop="fullname" />
        <el-table-column align="center" label="电话" prop="mobile" />

        <el-table-column align="center" label="所属装卸队">
          <template slot-scope="scope">
            <div>{{ scope.row.fleet ? scope.row.fleet.fleet_name : "" }}</div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="管理城市仓"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <div>{{ logisticsformat(scope.row?.fleet?.logistics) }}</div>
          </template>
        </el-table-column>
        <el-table-column label="状态" align="center">
          <template slot-scope="scope">
            <el-button type="success" plain v-if="scope.row.state == 1"
              >启用</el-button
            >
            <el-button type="warning" plain v-else>禁用</el-button>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="personEdit(scope.row)">
              编辑
            </el-button>
            <el-button
              type="text"
              @click="updatePerson(scope.row, scope.$index)"
            >
              {{ scope.row.state == 1 ? "禁用" : "启用" }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="fy">
      <el-pagination
        type="primary"
        background
        :current-page="from.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="from.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

    <!-- 添加/装卸账号 -->
    <el-dialog
      :center="true"
      @close="personClose"
      :visible.sync="personVisible"
      v-if="personVisible"
      :title="personTitle"
      :append-to-body="true"
    >
      <div style="width: 80%">
        <el-form
          :model="personForm"
          :rules="rules"
          ref="ruleForm"
          label-width="20%"
          class="demo-ruleForm"
        >
          <el-form-item label="姓名" prop="fullname">
            <el-input
              v-model="personForm.fullname"
              placeholder="请输入姓名"
            ></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="mobile">
            <el-input
              v-model="personForm.mobile"
              placeholder="请输入手机号"
            ></el-input>
          </el-form-item>
          <el-form-item label="所属装卸队" prop="fleet_id">
            <el-select
              v-model="personForm.fleet_id"
              style="width: 100%"
              placeholder="请选择所属装卸队"
            >
              <el-option
                v-for="item in FleetList"
                :key="item.id"
                :label="item.fleet_name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <el-button @click="personVisible = false">取消</el-button>
        <el-button type="primary" @click="personSure">确定</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "stevedoring-account",
  components: {},
  data() {
    return {
      //表格搜索分页基础信息
      tableData: [],
      //查询输入框下拉框从此配置

      from: {
        // name: "",
        page: 1,
        pageSize: 10,
      },
      // currentPage: 1,
      total: 0,
      loading: false,
      //页面功能字段存储
      personbtn: "",
      personVisible: false,
      personForm: {
        id: "",
        fullname: "", //	string	账号名称
        mobile: "", //	string	手机号
        fleet_id: "", //	number	所属装卸队id
      },
      personTitle: "",
      FleetList: [],
      rules: {
        fullname: [
          { required: true, message: "请输入装卸队名称", trigger: "blur" },
        ],
        mobile: [
          {
            required: true,
            message: "请输入联系电话",
            // blur和change事件触发检验
            trigger: ["blur", "change"],
          },
          // 正则判断为字母或数字
          {
            pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
            // 正则检验前先将值转为字符串
            transform(value) {
              return String(value);
            },
            message: "该手机号码不符合规范",
            trigger: ["blur"],
          },
        ],
        fleet_id: [
          {
            required: true,
            message: "请选择管理城市仓",
            trigger: "change blur",
          },
        ],
      },
    };
  },

  created() {
    this.hqlist();
  },
  methods: {
    hqgetLogisticList() {
      this.$api.logistics
        .getFleetList({
          page: 1,
          status: 1,
          pageSize: 999999,
        })
        .then((res) => {
          this.FleetList = res.data.data;
        });
    },
    //格式化
    logisticsformat(e) {
      return e ? e.map((el) => el.name).join(",") : "";
    },
    //添加按钮
    Addfleet() {
      this.personTitle = "添加";
      this.hqgetLogisticList();
      Object.assign(this.$data.personForm, this.$options.data().personForm);
      this.personVisible = true;
      this.personbtn = "add";
    },
    //编辑点击保存
    personSure() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.personbtn == "add") {
            this.$api.logistics.addFleetUser(this.personForm).then((res) => {
              this.$message({
                message: "添加成功",
                type: "success",
              });
              this.personbtn = "";
              this.hqlist();
              this.personVisible = false;
            });
          } else {
            this.$api.logistics.updateFleetUser(this.personForm).then((res) => {
              this.$message({
                message: "编辑成功",
                type: "success",
              });
              this.personbtn = "";
              this.hqlist();
              this.personVisible = false;
            });
          }
        }
      });
    },
    //编辑弹框点击关闭
    personClose() {
      this.personVisible = false;
    },
    //编辑接口
    personEdit(detail) {
      this.personTitle = "编辑";
      this.personbtn = "edit";
      this.hqgetLogisticList();
      this.$api.logistics.getFleetUserInfo({ id: detail.id }).then((res) => {
        this.personForm = JSON.parse(JSON.stringify(res.data));
        this.personVisible = true;
      });
    },
    //启用禁用
    updatePerson(detail, index) {
      this.$confirm(
        `确定要${detail.state == 1 ? "禁用" : "启用"}吗？`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true,
        }
      )
        .then(() => {
          this.$api.logistics
            .updateFleetUserStatus({
              id: detail.id,
              state: detail.state == 1 ? 2 : 1,
            })
            .then((res) => {
              this.$message({
                message: `已${detail.state == 1 ? "禁用" : "启用"}`,
                type: "success",
              });
              this.tableData[index].state = detail.state == 1 ? 2 : 1;
            });
        })
        .catch(() => {});
    },

    //基础表格查询搜索函数

    Refresh() {
      Object.assign(this.$data.from, this.$options.data().from);
      this.hqlist();
    },
    hqlist() {
      this.loading = true;
      this.$api.logistics
        .getFleetUserList(this.from)
        .then((res) => {
          console.log(res, "数据");
          this.tableData = res.data.data;
          this.total = res.data.total;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    handleSizeChange(val) {
      this.from.page = 1;
      this.from.pageSize = val;
      this.hqlist();
    },
    handleCurrentChange(val) {
      this.from.page = val;
      // this.currentPage = val;
      this.hqlist();
    },
  },
};
</script>
<style lang="scss">
.stevedoring-account {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  //   height: 100%;
  //   padding: 10px;
  box-sizing: border-box;
  //   background: #ffffff;
  .auto-table-title {
    background: #ffffff;
    padding: 13px 15px;
    box-sizing: border-box;
    display: flex;
    .seach {
      flex: 1;
      display: flex;
      justify-content: flex-start;
      margin-left: 10px;
      .inputs {
        width: 207px;
        margin-right: 8px;
      }
    }
    .bjsearch {
      background: #333333;
      border-color: #333333;
    }
  }
  .fy {
    text-align: right;
    box-sizing: border-box;
    padding: 10px 20px;
  }
}
</style>
